<template>
  <el-table
    :data="list"
    style="width: 100%"
    stripe
    max-height="220"
    border
    show-summary
    :sum-text="`共 ${list.length} 个任务`"
  >
    <el-table-column prop="targetTaskName" label="任务名称"> </el-table-column>
    <el-table-column label="任务金额（元）">
      <template slot-scope="scope">
        <div class="cell-wrapper">
          <el-input
            v-model="scope.row.targetTaskPrice"
            :disabled="!scope.row.editable"
            @input="handleInput(scope.row)"
          ></el-input>
          <div v-if="showEdit" class="edit-container">
            <i
              class="el-icon-edit"
              v-if="!scope.row.editable"
              @click="handleInputEdit(scope.row)"
            ></i>
            <i
              class="el-icon-check"
              v-else
              @click="handleInputEditDone(scope.row)"
            ></i>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  name: "GroupTaskTable",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    tableData: {
      handler(val) {
        this.list = val.map((item) => {
          return {
            ...item,
            editable: false,
            // originalPrice: item.targetTaskPrice,
          };
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    handleInputEdit(e) {
      e.editable = !e.editable;
    },
    handleInputEditDone(e) {
      e.editable = !e.editable;
      if (e.targetTaskPrice.toString().startsWith(".")) {
        e.targetTaskPrice = "0" + e.targetTaskPrice;
      }
      if (e.targetTaskPrice.toString().endsWith(".")) {
        // 去掉末尾的小数点
        e.targetTaskPrice = e.targetTaskPrice + "0";
      }
      const total = this.publicGetTotal();
      this.$emit("moneyChanged", total);
    },
    handleInput(row) {
      // 使用正则表达式限制输入最多两位小数
      if (row.targetTaskPrice !== null) {
        row.targetTaskPrice = row.targetTaskPrice
          .toString()
          .replace(/[^\d.]/g, "")
          .replace(/(\.\d{2}).+/, "$1");
        // 不能超过 originalPrice
        // if (row.targetTaskPrice > row.originalPrice) {
        //   row.targetTaskPrice = row.originalPrice;
        // }
      }
    },
    publicGetList() {
      // 处理当前未点击确认 ，全部确认
      this.list.forEach((item) => {
        item.editable = false;
      });
      const total = this.publicGetTotal();
      this.$emit("moneyChanged", total);
      return this.list;
    },
    publicGetTotal() {
      let total = 0;
      total = this.list.reduce(
        (acc, cur) =>
          acc + (cur.targetTaskPrice ? Number(cur.targetTaskPrice) : 0),
        0
      );
      return total.toFixed(2);
    },
  },
};
</script>
<style scoped lang="scss">
.cell-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit-container {
  padding: 0px 10px;
}
</style>
