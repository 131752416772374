<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="700px"
    @close="beforClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    top="10vh"
  >
    <div>
      <el-table :data="tableData" :border="true" max-height="500" row-key="id">
        <el-table-column label="业务员" prop="salesmanName">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.salesman"
              style="width: 100%"
              filterable
              remote
              reserve-keyword
              placeholder="搜索业务员"
              :remote-method="remoteMethodByInput"
              value-key="id"
              @focus="handleSelectyFocus"
              v-loadmore="handleScroll"
              @visible-change="handleVisibleChange"
            >
              <el-option
                v-for="item in salesmanList"
                :key="item.id"
                :label="item.name + '/' + item.userTel"
                :value="item"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="分派子任务数" prop="allocationCount">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.allocationCount"
              :min="1"
              :max="Number(taskCanAllocationCount + scope.row.allocationCount)"
              :step="1"
              :placeholder="'剩余可分配子任务数：' + taskCanAllocationCount"
              style="width: 100%"
              @change="handleInputChange()"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="" prop="" width="100px" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="tableData.splice(scope.$index, 1)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="handleAddRow"
        icon="el-icon-plus"
        v-if="taskCanAllocationCount > 0"
        >增加一行</el-button
      >
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitLoading"
        >提交</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import {
  csoMemberPage,
  contractTargetTaskResidueCount,
  contractDoTaskAddBatch,
} from "@/api/complianceCso/salesman";
import { debounce } from "lodash";
export default {
  name: "childtaskAllocation",
  props: {},
  data() {
    return {
      visible: false,
      title: "分配业务员",
      tableData: [],
      salesmanList: [],
      totalTaskCanAllocationCount: 0,
      taskCanAllocationCount: 0,
      emptyRow: { salesman: undefined, allocationCount: 1, id: undefined },
      loading: false,
      targetTaskId: undefined,
      submitLoading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {},
  watch: {
    tableData: {
      handler(val, oldVal) {
        this.handleInputChange();
      },
      immediate: true,
    },
  },
  methods: {
    handleInputChange() {
      const totalCount = this.tableData.reduce(
        (acc, cur) => acc + cur.allocationCount || 0,
        0
      );
      this.taskCanAllocationCount =
        this.totalTaskCanAllocationCount - totalCount;
      this.title = `分配业务员（剩余可分配子任务数：${this.taskCanAllocationCount}）`;
    },
    publicOpen(options) {
      this.visible = true;
      if (options.targetTaskId) {
        this.targetTaskId = options.targetTaskId;
        this.getTaskCanAllocationCount(options.targetTaskId);
      }
    },
    getTaskCanAllocationCount(targetTaskId) {
      contractTargetTaskResidueCount(targetTaskId)
        .then((res) => {
          this.totalTaskCanAllocationCount = res && res > 0 ? res : 0;
          this.taskCanAllocationCount = this.totalTaskCanAllocationCount;
          this.title = `分配业务员（剩余可分配子任务数：${this.totalTaskCanAllocationCount}）`;
          //   if (this.totalTaskCanAllocationCount > 0) {
          //     this.tableData = [{ ...this.emptyRow, id: 0 }];
          //   }
          if (this.totalTaskCanAllocationCount > 0) {
            this.$nextTick(() => {
              this.handleAddRow();
            });
          }
        })
        .finally(() => {});
    },
    handleClose() {
      this.visible = false;
    },
    beforClose() {
      this.tableData = [];
      this.salesmanList = [];
      this.totalTaskCanAllocationCount = 0;
      this.taskCanAllocationCount = 0;
      this.emptyRow = {
        salesman: undefined,
        allocationCount: 1,
        id: undefined,
      };
      this.loading = false;
      this.targetTaskId = undefined;
    },
    handleAddRow() {
      this.tableData.push({ ...this.emptyRow, id: this.tableData.length });
    },
    handleSubmit: debounce(function () {
      if (!this.tableData.length) {
        return this.$message.error("请选择业务员");
      }
      if (
        this.tableData.some(
          (item) => !item.salesman || item.allocationCount <= 0
        )
      ) {
        return this.$message.error("请选择业务员或分配子任务数");
      }
      const infoQOS = [];
      this.tableData.forEach((item) => {
        if (item.salesman && item.allocationCount > 0) {
          infoQOS.push({
            csoMemberId: item.salesman.id,
            taskNum: item.allocationCount,
          });
        }
      });
      const obj = {
        taskId: this.targetTaskId,
        infoQOS: infoQOS,
      };
      this.submitLoading = true;
      contractDoTaskAddBatch(obj)
        .then((res) => {
          this.$message.success("分配成功");
          this.$emit("refresh");
          this.handleClose();
        })
        .finally(() => {
          this.submitLoading = false;
        });
    }, 500),
    handleVisibleChange(val) {},
    handleScroll() {
      if (this.salesmanList.length >= this.total) {
        return;
      }
      this.currentPage++;
      this.remoteMethod(this.query); // 重新请求新一页的数据
    },
    handleSelectyFocus() {
      this.currentPage = 1;
      this.salesmanList = [];
      this.remoteMethod("");
    },
    remoteMethodByInput(query) {
      this.currentPage = 1;
      this.remoteMethod(query);
    },
    remoteMethod(query) {
      // if (query !== "") {
      this.loading = true;
      csoMemberPage({
        name: query,
        auditStatus: 1,
        current: this.currentPage,
        size: this.pageSize,
      })
        .then((res) => {
          const records = res.records || [];
          this.salesmanList =
            this.currentPage === 1
              ? records
              : this.salesmanList.concat(records);
          this.total = res.total || 0;
        })
        .finally(() => {
          this.loading = false;
        });
      // }
    },
  },
};
</script>
<style scoped lang="scss"></style>
