<template>
  <el-table
    :data="list"
    style="width: 100%"
    stripe
    max-height="280"
    border
    show-summary
    :sum-text="`共 ${tableData.length} 人`"
    :summary-method="getSummaries"
  >
    <el-table-column prop="csoMemberName" label="业务员名称"> </el-table-column>
    <el-table-column prop="targetTaskName" label="任务名称"> </el-table-column>
    <el-table-column label="完税凭证金额（元）" prop="amount">
      <!-- <template slot-scope="scope">
        {{ getMoney(scope.row) }}
      </template> -->
      <template slot-scope="scope">
        <div class="cell-wrapper">
          <el-input
            v-model="scope.row.price"
            :disabled="!scope.row.editable"
            @input="handleInput(scope.row)"
          ></el-input>
          <div v-if="showEdit" class="edit-container">
            <i
              class="el-icon-edit"
              v-if="!scope.row.editable"
              @click="handleInputEdit(scope.row)"
            ></i>
            <i
              class="el-icon-check"
              v-else
              @click="handleInputEditDone(scope.row)"
            ></i>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  name: "GroupTaskTable",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  watch: {
    tableData: {
      handler(val) {
        this.list = val.map((item) => {
          console.log(item.price);
          return {
            ...item,
            editable: false,
          };
        });
      },
      immediate: true,
    },
  },
  methods: {
    handleInputEdit(e) {
      e.editable = !e.editable;
    },
    handleInputEditDone(e) {
      e.editable = !e.editable;
      if (e.price.toString().startsWith(".")) {
        e.price = "0" + e.price;
      }
      if (e.price.toString().endsWith(".")) {
        // 去掉末尾的小数点
        e.price = e.price + "0";
      }
      const total = this.publicGetTotal();
      this.$emit("moneyChanged", total);
    },
    handleInput(row) {
      // 使用正则表达式限制输入最多两位小数
      if (row.price !== null) {
        row.price = row.price
          .toString()
          .replace(/[^\d.]/g, "")
          .replace(/(\.\d{2}).+/, "$1");
        // 不能超过 originalPrice
        // if (row.price > row.originalPrice) {
        //   row.price = row.originalPrice;
        // }
      }
    },
    publicGetList() {
      this.list.forEach((item) => {
        item.editable = false;
      });
      console.log(this.list);
      const total = this.publicGetTotal();
      this.$emit("moneyChanged", total);
      return this.list;
    },
    publicGetTotal() {
      let total = 0;
      total = this.list.reduce(
        (acc, cur) => acc + (cur.price ? Number(cur.price) : 0),
        0
      );
      return total.toFixed(2);
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总计";
        } else if (column.property === "amount") {
          let total = 0;
          data.forEach((item) => {
            const { price = 0 } = item;
            total += Number(price);
          });
          sums[index] = total.toFixed(2);
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
  },
};
</script>
<style scoped lang="scss">
.cell-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit-container {
  padding: 0px 10px;
}
</style>
