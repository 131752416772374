var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "static-template" },
    [
      _vm._l(_vm.templateList, function (item, index) {
        return _c("div", { key: index, staticClass: "template-item" }, [
          !item.hide
            ? _c("div", { staticClass: "tip" }, [
                _c(
                  "div",
                  [
                    _vm._v(" " + _vm._s(item.filedLabel)),
                    item.isMust
                      ? _c("span", { staticClass: "weight" }, [_vm._v("*")])
                      : _vm._e(),
                    !_vm.preview && item.useDefaultFile
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleOpenFileDlg(item)
                              },
                            },
                          },
                          [_vm._v("附件")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { color: "#f5222d", margin: "5px 0px" } },
                  [_vm._v(_vm._s(item.failReason))]
                ),
              ])
            : _vm._e(),
          item.useDefaultFile && item.defaultValue
            ? _c(
                "div",
                _vm._l(_vm.getFileList(item), function (fileItem, fileIndex) {
                  return _c("div", { key: fileIndex }, [
                    _c(
                      "div",
                      {
                        staticClass: "file-item",
                        on: {
                          click: function ($event) {
                            return _vm.handlePreviewFile(fileItem)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(fileItem.name) + " ")]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
          !item.hide && item.type === _vm.fieldType.Iconst_Input
            ? _c(
                "div",
                { staticClass: "value" },
                [
                  _vm.preview
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.childTaskDetail
                              ? _vm.childTaskDetail[item.fieldName]
                              : ""
                          )
                        ),
                      ])
                    : _c("el-input", {
                        attrs: {
                          disabled: !item.useDefault,
                          size: "small",
                          placeholder: "",
                          multiline: "",
                          type: "textarea",
                        },
                        model: {
                          value: item.defaultValue,
                          callback: function ($$v) {
                            _vm.$set(item, "defaultValue", $$v)
                          },
                          expression: "item.defaultValue",
                        },
                      }),
                ],
                1
              )
            : !item.hide && item.type === _vm.fieldType.Iconst_Pic
            ? _c(
                "div",
                { staticClass: "value" },
                [
                  _vm.preview
                    ? _c("ossFileUpload", {
                        attrs: {
                          value: _vm.fileList(item),
                          ossUpload: true,
                          withCover: true,
                          disabled: true,
                          picSize: 88,
                          listType: item.listType || "picture-card",
                        },
                      })
                    : _c("div", { staticClass: "pic-views" }, [
                        _c("div", { staticClass: "select-pic" }, [
                          _c("i", { staticClass: "el-icon-plus" }),
                          _c("span"),
                        ]),
                      ]),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      _c("taskFileDlg", {
        ref: "taskFileDlgRef",
        attrs: { title: "课件附件" },
        on: { submit: _vm.handleSubmit },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }