<template>
  <div class="classification">
    <div class="header">
      <el-form
        ref="formTool"
        :model="formTool"
        :inline="true"
        @submit.native.prevent
        style="text-align: left"
      >
        <el-form-item label="">
          <el-input
            style="width: 150px"
            v-model="formTool.memberName"
            placeholder="搜索业务员名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item label="">
          <el-input
            style="width: 150px"
            v-model="formTool.memberAccount"
            placeholder="搜索业务员账号"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item label="">
          <el-input
            style="width: 150px"
            v-model="formTool.contractName"
            placeholder="搜索合同名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item label="">
          <el-input
            style="width: 150px"
            v-model="formTool.medicalShopName"
            placeholder="搜索医药公司"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item label="">
          <el-input
            style="width: 150px"
            v-model="formTool.targetName"
            placeholder="搜索指标名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item label="">
          <el-input
            style="width: 150px"
            v-model="formTool.taskName"
            placeholder="搜索任务名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="status" label="">
          <el-select
            v-model="formTool.status"
            placeholder="筛选状态"
            size="small"
            style="width: 150px"
            clearable
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, index) in statusArr"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)"
            >查询</el-button
          >
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btn-group">
        <el-button type="primary" size="small" @click="handleBatchUpload"
          >批量上传</el-button
        >
      </div>
    </div>
    <div class="center" style="position: relative">
      <MyTable
        :index="false"
        :table-data="tableData"
        :page="page"
        :table-option.sync="tableOption"
        @page-change="tablePageChange"
        @handleSelectionChange="handleSelectionChange"
        :selectableCanBeDisable="true"
        selection
      >
        <template slot="status" slot-scope="scope">
          {{ statusMap[scope.row.status] }}
        </template>
        <template slot="tools" slot-scope="scope">
          <div class="table-op-col">
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="openDetail(scope.row)"
              >完税凭证列表</el-link
            >
          </div>
        </template>
      </MyTable>
    </div>
    <ReceiptDetail
      ref="receiptDetailRef"
      @onRefresh="handleRefresh"
    ></ReceiptDetail>
  </div>
</template>

<script>
import MyTable from "@/components/myTable/index.vue";
import {
  csoDutyPaidProofParentPage,
  csoDutyPaidProofVoucherMoney,
} from "@/api/complianceCso/salesman";
import ReceiptDetail from "./components/receipt-detail.vue";
export default {
  components: {
    MyTable,
    ReceiptDetail,
  },
  data() {
    return {
      formTool: {
        memberName: "",
        memberAccount: "",
        contractName: "",
        medicalShopName: "",
        targetName: "",
        taskName: "",
        status: "",
      },
      statusMap: {
        1: "进行中",
        2: "已完结",
      },
      tableData: [],
      tableOption: [
        { label: "业务员名称", prop: "memberName" },
        { label: "业务员账号", prop: "memberAccount" },
        { label: "合同名称", prop: "contractName" },
        { label: "医药公司", prop: "draftShopName" },
        { label: "指标名称", prop: "targetName" },
        { label: "任务名称", prop: "targetTaskName" },
        { label: "已完成任务金额", prop: "amount" },
        { label: "已审核凭证金额", prop: "taskAmount" },
        { label: "状态", prop: "status", slot: true },
        { label: "操作", prop: "tools", slot: true, width: "180px" },
      ],
      page: { total: 0, current: 1, size: 10 },
      batchList: [], //批量上传凭证
      batchTotalMoney: 0, //批量上传凭证总金额
    };
  },
  computed: {
    statusArr() {
      // 获取 statusMap 的 key 数组
      const kesyArr = Object.keys(this.statusMap);
      const valuesArr = Object.values(this.statusMap);
      const arr = [];
      for (let i = 0; i < kesyArr.length; i++) {
        arr.push({ label: valuesArr[i], value: kesyArr[i] });
      }
      return arr;
    },
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    handleBatchUpload() {
      if (!this.batchList.length) {
        return this.$message.warning("请先选择需要上传的凭证");
      }
      const dutyPaidProofQOS = []; //{"csoMemberId": 0,"targetTaskId": 0}
      this.batchList.forEach((item) => {
        const {
          memberId,
          memberName,
          targetTaskId,
          targetTaskName,
          amount = 0,
          taskAmount = 0,
          stayTaskAmount = 0,
        } = item;
        const fixedAmount = this.fixedTwoMoney(
          amount - taskAmount - stayTaskAmount
        );
        dutyPaidProofQOS.push({
          csoMemberId: item.memberId,
          csoMemberName: item.memberName,
          targetTaskId: item.targetTaskId,
          targetTaskName: item.targetTaskName,
          amount: item.amount, //已完成任务金额
          taskAmount: item.taskAmount, //已审核凭证金额
          stayTaskAmount: item.stayTaskAmount, //待审核凭证金额
          price: fixedAmount, //已审核凭证金额
        });
      });
      this.$refs.receiptDetailRef.open({
        action: 1,
        dutyPaidProofQOS,
        isBatch: true,
        // batchTotalMoney: this.batchTotalMoney,
      });
    },
    fixedTwoMoney(num) {
      return (Math.round(num * 100) / 100).toFixed(2);
    },
    openDetail(row) {
      this.$router.push({
        path: "/compliancecso/compliancecso-taxPaymentReceipt-receiptList",
        query: { memberId: row.memberId, targetTaskId: row.targetTaskId },
      });
    },
    handleSelectionChange(val) {
      this.batchList = val;
      // this.getBatchListTotalAmount(val);
    },
    getBatchListTotalAmount(list) {
      // if (!list.length) {
      //   this.batchTotalMoney = 0;
      //   return;
      // }
      // let dutyPaidProofQOS = [];
      // list.forEach((item) => {
      //   dutyPaidProofQOS.push({
      //     csoMemberId: item.memberId,
      //     targetTaskId: item.targetTaskId,
      //   });
      // });
      // csoDutyPaidProofVoucherMoney(dutyPaidProofQOS)
      //   .then((res) => {
      //     if (res) {
      //       this.batchTotalMoney = res;
      //     }
      //   })
      //   .catch(() => {})
      //   .finally(() => {});
    },
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = { current, size, ...this.formTool };
      this.listLoading = true;
      csoDutyPaidProofParentPage(params)
        .then((res) => {
          this.tableData = res.records.map((item) => {
            const { amount = 0, taskAmount = 0, stayTaskAmount = 0 } = item;
            item.beAllowOperation = amount > taskAmount + stayTaskAmount;
            return item;
          });
          this.page.total = res.total;
        })
        .catch(() => {})
        .finally(() => {
          this.listLoading = false;
        });
    },
    handleRefresh() {
      this.getList();
    },
    tablePageChange() {
      this.getList();
    },
    handleReset() {
      for (let key in this.formTool) {
        this.formTool[key] = "";
      }
      this.getList(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;
  background: white;

  .header {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);
  }

  .table-op-col {
    display: flex;
    align-items: center;

    .el-link {
      & + .el-link {
        margin-left: 8px;
      }
    }
  }
  .btn-group {
    padding-right: 10px;
  }
}
</style>
